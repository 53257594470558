export const colors = [
    '#8884d8', '#82ca9d', '#ffc658', '#d0ed57', '#a4de6c',
    '#d88884', '#84d8d8', '#d884d8', '#d8d884', '#d8a484'
];

export const effectiveGrowthChartColors = {
    activations: '#82ca9d',
    deactivations: '#d88884',
    growth: '#8884d8',
    previousGrowth: '#3a32ff'
};


export const audienceActivitiesChartColors = {
    activations: '#82ca9d',
    deactivations: '#d88884',
    growth: '#8884d8',
    new_users: '#2767fd',
    opt_ins: '#2767fd',
};

export const localeMapping = {
    "en_ZA": "English (South Africa)",
    "af": "Afrikaans",
    "es_VE": "Spanish (Venezuela)",
    "pa_IN": "Punjabi (India)",
    "en_US": "English (United States)",
    "en_GB": "English (United Kingdom)",
    "fr_FR": "French (France)",
    "de_DE": "German (Germany)",
    "it_IT": "Italian (Italy)",
    "es_ES": "Spanish (Spain)",
    "pt_PT": "Portuguese (Portugal)",
    "pt_BR": "Portuguese (Brazil)",
    "zh_CN": "Chinese (Simplified)",
    "zh_TW": "Chinese (Traditional)",
    "ja_JP": "Japanese",
    "ko_KR": "Korean",
    "hi_IN": "Hindi (India)",
    "ar_SA": "Arabic (Saudi Arabia)",
    "ru_RU": "Russian (Russia)",
    "nl_NL": "Dutch (Netherlands)",
    "sv_SE": "Swedish (Sweden)",
    "da_DK": "Danish (Denmark)",
    "no_NO": "Norwegian (Norway)",
    "fi_FI": "Finnish (Finland)",
    "tr_TR": "Turkish (Turkey)",
    "he_IL": "Hebrew (Israel)",
    "th_TH": "Thai (Thailand)",
    "vi_VN": "Vietnamese (Vietnam)",
    "ms_MY": "Malay (Malaysia)",
    "id_ID": "Indonesian",
    "pl_PL": "Polish (Poland)",
    "cs_CZ": "Czech (Czech Republic)",
    "hu_HU": "Hungarian (Hungary)",
    "ro_RO": "Romanian (Romania)",
    "sk_SK": "Slovak (Slovakia)",
    "bg_BG": "Bulgarian (Bulgaria)",
    "uk_UA": "Ukrainian (Ukraine)",
    "el_GR": "Greek (Greece)",
    "hr_HR": "Croatian (Croatia)",
    "sr_RS": "Serbian (Serbia)",
    "lt_LT": "Lithuanian (Lithuania)",
    "lv_LV": "Latvian (Latvia)",
    "et_EE": "Estonian (Estonia)",
    "sl_SI": "Slovenian (Slovenia)",
    "mt_MT": "Maltese (Malta)",
    "is_IS": "Icelandic (Iceland)",
    "vi": "Vietnamese",
    "en": "English",
    "ja": "Japanese",
};
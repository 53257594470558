import React from 'react';

const TelemetrySettingBlock = ({ name, value, iconSrc, altText, title, colorClass, checked }) => {
  return (
    <div className="telemetry-setting-block">
      <label><input type="radio" name={name} value={value} defaultChecked={checked}/></label>
      <div className={`telemetry-setting-icon ${colorClass}`}><img src={iconSrc} alt={altText} /></div>
      <h3>{title}</h3>
    </div>
  );
};

export default TelemetrySettingBlock;

import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchStoreUsers } from "../../services/apiService";
import { useTelemetryContext } from "../../contexts/TelemetryContext";
import { useNavigate } from 'react-router-dom';

function StoreUsers({ paginationPerPage ,searchTerm, storeId}) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage || 10);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await fetchStoreUsers(storeId, currentPage, perPage, searchTerm);
                setData(responseData.data);
                setTotalRows(responseData.total);
                setPerPage(responseData.per_page);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [storeId, currentPage, perPage, searchTerm]);

    const handleUserDetailClick = (userId) => {
        // Todo
        // Redirect to User detail page only without product
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            cell: row => (
                <span className="id-span link-icon" onClick={() => handleUserDetailClick(row.id)}>
                    {row.id}
                </span>
            ),
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            cell: row => (
                <span className="data-user">
                    <img src="https://via.placeholder.com/40" alt="avatar" />
                    <span className="link-icon" onClick={() => handleUserDetailClick(row.id)}>
                        {row.email}
                    </span>
                </span>
            ),
        },
        {
            name: 'Name',
            selector: row => row.nick_name,
            sortable: true,
            cell: row => <span className="id-span">{row.nick_name}</span>,
        },
        {
            name: 'Marketing',
            selector: row => row.marketing_record,
            sortable: true,
            cell: row => (
                <span className={"badge" + (row.marketing_record === 1 ? " green-badge" : " orange-badge")}>
                    {row.marketing_record === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            name: 'Member Since',
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true,
        },
    ];

    return (
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={perPage}
                onChangePage={handlePageChange}
                responsive
                className="custom-data-table"
            />
    );
}

export default StoreUsers;

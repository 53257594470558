import React, { useState, useEffect } from 'react';
import profileicon from '../../img/customer-icon.svg';
import { addNewProduct, fetchStores } from '../../services/apiService'; 

export default function AddNewProductsForm() {
  const initialFormData = {
    store: '', 
    pluginTitle: '',
    pluginSlug: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [stores, setStores] = useState([]);
  const [settings, setSettings] = useState([{ key: '', value: '' }]);

  useEffect(() => {
    const loadStores = async () => {
      try {
        const storeData = await fetchStores(); 
        setStores(storeData);
        if (storeData.length > 0) {
          setFormData((prev) => ({
            ...prev,
            store: storeData[0].id, // Set the default store ID
          }));
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    loadStores();
  }, []);

  const generateSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove special characters
      .trim()
      .replace(/\s+/g, '-'); // Replace spaces with hyphens
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setFormData((prev) => ({
      ...prev,
      [field]: value,
      // Automatically update the slug if the pluginTitle field is being changed
      pluginSlug: field === 'pluginTitle' ? generateSlug(value) : prev.pluginSlug,
    }));
  };

  const handleSettingChange = (index, field, value) => {
    const newSettings = [...settings];
    newSettings[index][field] = value;
    setSettings(newSettings);
  };

  const addSetting = () => {
    setSettings([...settings, { key: '', value: '' }]);
  };

  const removeSetting = (index) => {
    const newSettings = settings.filter((_, i) => i !== index);
    setSettings(newSettings);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Prepare only the necessary data for submission
    const dataToSubmit = {
      store_id: formData.store, // Send the store ID here
      name: formData.pluginTitle,
      slug: formData.pluginSlug,
      settings: settings,
    };

    try {
      await addNewProduct(dataToSubmit);
      alert('Product added successfully!');
      // Reset the form fields
      setFormData(initialFormData);
      setSettings([{ key: '', value: '' }]); // Reset settings
    } catch (error) {
      alert('Failed to add product.');
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="telemetry-form-inline">
        <h4>Integration purpose:</h4>
        <div className="telemetry-form-box">
          <label>
            <input
              type="checkbox"
              defaultChecked
              value="Get Analytics through Telemetry"
            />{' '}
            Get Analytics through Telemetry
          </label>
          <label>
            <input
              type="checkbox"
              defaultChecked
              value="Sell through Telemetry"
            />{' '}
            Sell through Telemetry
          </label>
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>How do you monetize your Plugin?</h4>
        <div className="telemetry-form-box">
          <label>
            <input
              type="checkbox"
              value="Paid plan(s)"
            />{' '}
            Paid plan(s)
          </label>
          <label>
            <input
              type="checkbox"
              value="Paid add-ons and/or add-on bundles"
            />{' '}
            Paid add-ons and/or add-on bundles
          </label>
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Does your Plugin have a free plan?</h4>
        <div className="telemetry-form-box">
          <label className="toggle">
            <input type="checkbox" defaultChecked />
          </label>
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Store:</h4>
        <div className="telemetry-form-box">
          <select
            value={formData.store}
            onChange={(e) => handleInputChange(e, 'store')}
          >
            {stores.map((store) => (
              <option key={store.id} value={store.id}>
                {store.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="telemetry-form-inline">
        <h4>Plugin Title:</h4>
        <div className="telemetry-form-box">
          <input
            type="text"
            placeholder="Enter the title"
            value={formData.pluginTitle}
            onChange={(e) => handleInputChange(e, 'pluginTitle')}
          />
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Plugin Slug:</h4>
        <div className="telemetry-form-box">
          <input
            type="text"
            placeholder="Enter the Slug"
            value={formData.pluginSlug}
            onChange={(e) => handleInputChange(e, 'pluginSlug')}
          />
        </div>
      </div>

      <div className="telemetry-form-inline">
        <h4>Settings:</h4>
        <div className="telemetry-form">
          {settings.map((setting, index) => (
            <div key={index} className="settings-row">
              <div className="setting-input">
                <input
                  type="text"
                  placeholder="Key"
                  value={setting.key}
                  onChange={(e) => handleSettingChange(index, 'key', e.target.value)}
                />
              </div>
              <div className="setting-input">
                <input
                  type="text"
                  placeholder="Value"
                  value={setting.value}
                  onChange={(e) => handleSettingChange(index, 'value', e.target.value)}
                />
              </div>

              <button onClick={() => removeSetting(index)} className="remove-setting-button">
                ✖
              </button>
            </div>
          ))}
          <div>
            <button type="button" className="add-settings-button" onClick={addSetting}>
              Add Setting
            </button>
          </div>
        </div>
      </div>

      <div className="telemetry-form-inline">
        <h4>Plugin Icon:</h4>
        <div className="telemetry-form-box">
          <div className="telemetry-upload-logo">
            <div className="update-icon">
              <img src={profileicon} alt="Profile icon" />
            </div>
            <div className="upload-action">
              <label>
                Upload <input type="file" />
              </label>
              <p>
                200px X 200px
                <br />
                Up to 200KB
              </p>
            </div>
          </div>
        </div>
      </div>
      <button type="submit">Get Started 🚀</button>
    </form>
  );
}

import React, {useEffect, useState} from 'react';
import {Outlet, Link} from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import FilterMonth from '../reused-components/FilterMonth';
import ChartDropdown from '../reused-components/ChartDropdown';
import CompareDropdown from "../reused-components/CompareDropdown";
import GmtDropdown from "../reused-components/GmtDropdown";
import UserBlocks from "../reused-components/UserBlocks";
import ReportRange from "../reused-components/RangeDatePicker";
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import userIcon from '../../img/user.svg';
import gearIcon from '../../img/gear-icon.svg';
import installIcon from '../../img/installation-icon.svg';
import versionChart from '../../img/version-chart.jpg';
import flag from '../../img/flags/italy-flag.png';
import VersionsStackedAreaChart from "../reused-components/Charts/VersionsStackedAreaChart";
import StackedAreaChart from "../reused-components/Charts/LangLocaleStackedAreaChart";
import UninstallReasonsPieChart from "../reused-components/Charts/UninstallReasonsPieChart";
import TrendlineChart from "../reused-components/Charts/EffectiveGrowthTrendLineChart";
import AudienceActivitiesMixChart from "../reused-components/Charts/AudienceActivitiesMixChart";
import {fetchDashboardStats} from "../../services/apiService";
import moment from 'moment';
import GroupByDropdown from "../reused-components/GroupByDropdown";
import {getDefaultGroupByValue} from "../../utils/dataProcessing";
import {useTelemetryContext} from "../../contexts/TelemetryContext"; // Assuming you have moment.js installed

export default function Login() {
    document.title = "Dashboard | Telemetry";

    // Initialize the dates
    const [startDate, setStartDate] = useState(moment().subtract(90, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')); // 90 days ago
    const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')); // today
    const [compareStartDate, setCompareStartDate] = useState(''); // 90 days ago
    const [compareEndDate, setCompareEndDate] = useState(''); // today

    const [filterGroupBy, setFilterGroupBy] = useState('month'); // Default to 'month'
    const [userStats, setUserStats] = useState('');
    const [showGrowth, setShowGrowth] = useState(false);
    const [showComparisonDateRange, setShowComparisonDateRange] = useState(false);
    const {selectedProduct} = useTelemetryContext();

    const handleDateChange = (start, end) => {
        // Set start date to the start of the day
        const startOfDay = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        // Set end date to the end of the day
        const endOfDay = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        setStartDate(startOfDay);
        setEndDate(endOfDay);
    };


    const handleCompareDateChange = (start, end) => {
        // Set start date to the start of the day
        setShowGrowth(true);
        const startOfDay = moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        // Set end date to the end of the day
        const endOfDay = moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        setCompareStartDate(startOfDay);
        setCompareEndDate(endOfDay);
    };

    useEffect(() => {
        const defaultGroupBy = getDefaultGroupByValue(startDate, endDate);
        setFilterGroupBy(defaultGroupBy);
    }, [startDate, endDate]);


    const handleGroupByDropdownClick = (groupBy) => {
        setFilterGroupBy(groupBy);
    }

    const handleCompareToChange = () => {
        if (showComparisonDateRange) {
            setShowComparisonDateRange(false);
            setCompareStartDate('');
            setCompareEndDate('');
            setShowGrowth(false);
        } else {
            setShowComparisonDateRange(true);
        }
    }

    useEffect(() => {
        setUserStats({
            all_time_users: '-',
            all_time_installs: '-',
            activations: '-',
            deactivations: '-',
            uninstalls: '-',
        });
        const fetchData = async () => {
            try {
                const responseData = await fetchDashboardStats(selectedProduct.id);
                setUserStats({
                    all_time_users: responseData.all_time_users.toLocaleString(),
                    all_time_installs: responseData.all_time_installs.toLocaleString(),
                    activations: responseData.activations.toLocaleString(),
                    deactivations: responseData.deactivations.toLocaleString(),
                    uninstalls: responseData.uninstalls.toLocaleString(),
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [selectedProduct]);

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry"/></a>
                </div>
                <div className="telemetry-top">
                    <h1>Product Dashboard</h1>
                    <div className="telemetry-top-right">
                        <AddProducts/>
                        <Profile image={profileImage}/>
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1}/>
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-user-block">
                        <UserBlocks
                            img={userIcon}
                            wrapper="telemetry-user-block-orange"
                            inner="telemetry-orange"
                            number={userStats?.all_time_users}
                            userText="All-time Users"
                            tooltip="User info"
                        />
                        <UserBlocks
                            img={gearIcon}
                            wrapper="telemetry-user-block-green"
                            inner="telemetry-green"
                            number={userStats?.activations}
                            userText="Active Install"
                            tooltip="User info"
                            up={`${userStats?.deactivations} Deactivations`}
                            down={`${userStats?.uninstalls} Uninstalls`}
                        />
                        <UserBlocks
                            img={installIcon}
                            wrapper="telemetry-user-block-blue"
                            inner="telemetry-blue"
                            number={userStats?.all_time_installs}
                            userText="All-time Installs"
                            tooltip="User info"
                        />
                    </div>
                    <div className="telemetry-block-section">
                        <h2>Uninstall Reasons %</h2>
                        {selectedProduct ? (
                            <UninstallReasonsPieChart
                                productId={selectedProduct?.id}
                                // title="Uninstall Reasons %"
                                meta_key="uninstall-reasons"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                    </div>
                    <div className="telemetry-filter">
                        <ReportRange
                            onChange={handleDateChange}
                            startDate={startDate}
                            endDate={endDate}
                        />
                        <div id="reportrange"><label><input type="checkbox" onChange={handleCompareToChange}/> Compared
                            to</label></div>

                        {showComparisonDateRange &&
                            <ReportRange
                                onChange={handleCompareDateChange}
                                startDate={compareStartDate}
                                endDate={compareEndDate}
                            />}

                        <GroupByDropdown
                            handleGroupByDropdownClick={handleGroupByDropdownClick}
                            startDate={startDate}
                            endDate={endDate}
                            group_by_period={filterGroupBy}
                        />
                    </div>

                    <div className="telemetry-block-section">
                        <h2>Effective Growth</h2>
                        {selectedProduct ? (
                            <TrendlineChart
                                product_id={selectedProduct?.id}
                                title="Growth"
                                meta_key="effective-growth"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                    </div>
                    <div className="telemetry-block-section">
                        <h2>Audience Activities</h2>
                        {selectedProduct ? (
                            <AudienceActivitiesMixChart
                                product_id={selectedProduct?.id}
                                title="Audience Activities"
                                meta_key="audience-activities"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                    </div>
                    <div className="telemetry-block-section">
                        <h2>Version Breakdowns</h2>
                        {selectedProduct ? (
                            <VersionsStackedAreaChart
                                product_id={selectedProduct?.id}
                                title="Plugin Versions"
                                meta_key="site-meta/version"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                        {selectedProduct ? (
                            <VersionsStackedAreaChart
                                product_id={selectedProduct?.id}
                                title="WP Versions"
                                meta_key="site-meta/wp_version"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                        {selectedProduct ? (
                            <VersionsStackedAreaChart
                                product_id={selectedProduct?.id}
                                title="PHP Versions"
                                meta_key="site-meta/php_version"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                        {selectedProduct ? (
                            <VersionsStackedAreaChart
                                product_id={selectedProduct?.id}
                                title="SDK Versions"
                                meta_key="site-meta/sdk_version"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                    </div>
                    <div className="telemetry-block-section">
                        <h2>Location & Language </h2>
                        {selectedProduct ? (
                            <StackedAreaChart
                                product_id={selectedProduct?.id}
                                title="Countries"
                                meta_key="site-meta/wp_location"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                        {selectedProduct ? (
                            <StackedAreaChart
                                product_id={selectedProduct?.id}
                                title="Languages"
                                meta_key="site-meta/wp_locale"
                                group_by_period={filterGroupBy}
                                start_date={startDate}
                                end_date={endDate}
                                compare_start_date={compareStartDate}
                                compare_end_date={compareEndDate}
                                showGrowth={showGrowth}
                            />
                        ) : (
                            <p>Loading chart...</p>
                        )}
                    </div>
                </section>
            </main>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchThemesPlugins } from '../../services/apiService'; // Replace with your actual API service

const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        className: "telemetry-form-block-1-4"
    },
    {
        name: 'Title',
        selector: row => row.name,
        sortable: true,
        className: "telemetry-form-block-full"
    },
    {
        name: 'Slug',
        selector: row => row.slug,
        sortable: true,
        className: "telemetry-form-block-full"
    },
    {
        name: 'State',
        selector: row => row.state,
        sortable: true,
        cell: row => (
            <span className={`badge ${row.state === 'active' ? 'green-badge' : 'orange-badge'}`}>
                {row.state}
            </span>
        ),
    },
    {
        name: 'Last Updated',
        selector: row => row.updated_at,
        sortable: true,
        cell: row => new Date(row.updated_at).toLocaleDateString(),
    },
];

function ThemesPluginsTable({ siteId }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10); // Adjust the number of rows per page
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchThemesPlugins(siteId, currentPage, rowsPerPage);

                // Assuming the response has the structure { data: [...], total: number }
                const responseData = response.data || [];
                const totalRecords = response.total || 0;

                // Manually assign unique IDs based on the current page and index
                const dataWithIds = responseData.map((item, index) => ({
                    ...item,
                    id: (currentPage - 1) * rowsPerPage + (index + 1), // Generate unique id for each item
                }));

                setData(dataWithIds);
                setTotalRows(totalRecords); 
            } catch (error) {
                console.error('Error fetching themes and plugins data:', error);
                setData([]); // Reset data in case of error
            }
            setLoading(false);
        };

        fetchData();
    }, [siteId, currentPage, rowsPerPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={rowsPerPage}
            responsive
            progressPending={loading}
            className="custom-data-table"
            noDataComponent="No records to display"
        />
    );
}

export default ThemesPluginsTable;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../img/search-icon.svg';
import closeIcon from '../../img/close-icon.svg';
import prevIcon from '../../img/arrow-prev.svg';

const SearchFilter = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showInput, setShowInput] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(searchTerm); 
  };

  const handleClear = () => {
    setShowInput(false); 
    setSearchTerm('');
    onSearch('');
  };


  return (
    <div className="search-filter-container">
      <form onSubmit={handleSearch} className="search-form">
        {!showInput ? (
          <button
            type="button"
            className="search-button search-icon"
            onClick={() => setShowInput(true)}
          >
			<img src={searchIcon} alt="Search" /> {/* Magnifying glass symbol */}
          </button>
        ) : (
          <>
            <button
              type="button"
              className="search-button back-button"
              onClick={handleClear}
            >
              <img src={prevIcon} alt="Search" /> {/* Back arrow symbol */}
            </button>
            <input
              type="text"
              placeholder="Enter ID or Address..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              type="button"
              className="search-button clear-button"
              onClick={handleClear}
            >
			<img src={closeIcon} alt="Search" />{/* Cross symbol */}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

SearchFilter.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchFilter;

import React from "react";

const Notification = ({ colorClass, content, title }) => {
  return (
    <div className={`notification ${colorClass?colorClass:''}`}>
      <p>
        {title && <strong>{title}</strong>} {content}
      </p>
    </div>
  );
};

export default Notification;

import React, { useState } from 'react';
import profileImage from '../../img/customer-icon.svg';

const TelemetryBoxProfileUp = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(profileImage);

  const handleFileChange = (event) => {
	const file = event.target.files[0];
	if(file){
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		
		const reader = new FileReader();
		reader.onloadend = () => {
		setPreview(reader.result);
		};
		reader.readAsDataURL(selectedFile);
	}
  };

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  return (
    <div className="telemetry-box-profile-up">
      <input 
        type="file" 
        id="fileInput" 
        style={{ display: 'none' }} 
        onChange={handleFileChange} 
		accept="image/png, image/gif, image/jpeg"
      />
      <div className='profile-thumbnail'>
	  <img src={preview} alt="Customizer" className='profile-image' />
	  </div>
      <p>300px X 300px<br /> Up to 200KB</p>
      <span className="btn" onClick={handleButtonClick}>Upload Logo</span>
      {file && <p>Selected file: {file.name}</p>}
    </div>
  );
};

export default TelemetryBoxProfileUp;

import React, {useEffect, useState} from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    AreaChart, Area
} from 'recharts';
import './graph.css';
import {fetchDashboardChartData} from "../../../services/apiService";
import {processAudienceActivitiesResults} from "../../../utils/dataProcessing";
import {audienceActivitiesChartColors, effectiveGrowthChartColors} from "../../../utils/constants";


const AudienceActivitiesMixChart = ({product_id, title, meta_key, group_by_period, start_date, end_date}) => {
    const [data, setData] = useState({customers: [], sites: [], productEvents: []});
    const [hoveredPeriod, setHoveredPeriod] = useState(null);
    const [overallTotals, setOverallTotals] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const responseData = await fetchDashboardChartData(product_id, meta_key, group_by_period, start_date, end_date);
                const processedData = processAudienceActivitiesResults(responseData);
                setData(processedData);

                // Calculate overall totals
                const totals = {
                    customers: responseData.customers.reduce((sum, event) => sum + event.total, 0),
                    sites: responseData.sites.reduce((sum, event) => sum + event.total, 0),
                    productEvents: {
                        total_activations: responseData.product_events.reduce((sum, event) => sum + event.total_activations, 0),
                        total_deactivations: responseData.product_events.reduce((sum, event) => sum + event.total_deactivations, 0),
                        growth: responseData.product_events.reduce((sum, event) => sum + (event.total_activations - event.total_deactivations), 0)
                    }
                };
                setOverallTotals(totals);

                setHoveredPeriod(null); // Default to overall totals
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchChartData();
    }, [product_id, meta_key, group_by_period, start_date, end_date]);

    const handleMouseMove = (state) => {
        if (state && state.activePayload && state.activePayload.length) {
            setHoveredPeriod(state.activePayload[0].payload.period);
        } else {
            setHoveredPeriod(null);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const filteredProductEventsData = hoveredPeriod ? data.productEvents.find(item => item.period === hoveredPeriod) : overallTotals.productEvents;
    const period = hoveredPeriod ? hoveredPeriod : "Overall";

    return (
        <div className="dashboard-chart-container">

            <div className="telemetry-block-container">

                <div className="telemetry-block-column">
                    <div className="telemetry-block-header green-badge">
                        <h3>New Opt Ins</h3>
                        <span className="badge"></span>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={data.customers}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            onMouseLeave={() => setHoveredPeriod(null)}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="period"/>
                            <YAxis/>
                            <Tooltip/>
                            <Line type="monotone" dataKey="total" stroke={audienceActivitiesChartColors.opt_ins}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div className="telemetry-block-column">
                    <div className="telemetry-block-header green-badge">
                        <h3>New Users</h3>
                        <span className="badge"></span>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                            data={data.sites}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            onMouseLeave={() => setHoveredPeriod(null)}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="period"/>
                            <YAxis/>
                            <Tooltip/>
                            <Line type="monotone" dataKey="total" stroke={audienceActivitiesChartColors.new_users}/>
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

            <div className="telemetry-block-container">
                <div className="telemetry-block-column">
                    <div className="telemetry-block-header green-badge">
                        <h3>Total Installations</h3>
                        <span className="badge"></span>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                            data={data.productEvents}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            onMouseMove={(state) => handleMouseMove(state, 'productEvents')}
                            onMouseLeave={() => setHoveredPeriod(null)}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="period"/>
                            <YAxis/>
                            <Tooltip/>
                            <Area type="monotone" dataKey="total_activations" stackId="1" stroke="#82ca9d"
                                  fill={audienceActivitiesChartColors.activations}/>
                            <Area type="monotone" dataKey="total_deactivations" stackId="1" stroke="#d88884"
                                  fill={audienceActivitiesChartColors.deactivations}/>
                            <Line type="monotone" dataKey="growth" stroke={audienceActivitiesChartColors.growth}/>
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className="telemetry-block-column">
                    <div className="table-column">
                        <div className="table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>Total Installations</th>
                                    <th>{period}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                <span className="badge"
                                      style={{backgroundColor: effectiveGrowthChartColors.activations}}>
                                    </span> Total Activations
                                    </td>
                                    <td>{filteredProductEventsData.total_activations}</td>
                                </tr>
                                <tr>
                                    <td>
                                <span className="badge"
                                      style={{backgroundColor: effectiveGrowthChartColors.deactivations}}>
                                    </span> Total Deactivations
                                    </td>
                                    <td>{filteredProductEventsData.total_deactivations}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td>
                                <span className="badge"
                                      style={{backgroundColor: effectiveGrowthChartColors.growth}}>
                                    </span> Growth
                                    </td>
                                    <td>{filteredProductEventsData.growth}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AudienceActivitiesMixChart;

import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import DataTableFilter from '../reused-components/DataTableFilter';
import UserTable from '../tables/UsersTable';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import React, {useEffect, useState} from "react";
import TelemetrySettingBlock from "../reused-components/ProductType";
import pluginIcon from "../../img/plugin-icon.svg";
import themeIcon from "../../img/theme-icon.svg";
import widgetIcon from "../../img/widget.svg";
import templateIcon from "../../img/template-icon.svg";
import TelemetryBoxProfileUp from "../reused-components/ProfileUpload";
import TelemetryFormBlock from "../reused-components/FormBlock";
import Avatar from "../reused-components/Avatar";
import {fetchDashboardStats, fetchUser} from "../../services/apiService";
import {useParams} from "react-router-dom";
import SitesTable from "../tables/SitesTable";
import EventsLog from "./EventsLog";
import ProductEventsLogTable from "../tables/ProductEventsLogTable";
import TelemetryAccordion from "../reused-components/Accordian";

export default function UserDetail() {
    document.title = "Users | Telemetry";
    const {userId} = useParams();
    const [userDetails, setUserDetails] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await fetchUser(userId);
                setUserDetails(responseData);
                console.log(userDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

    }, [userId]);

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry"/></a>
                </div>
                <div className="telemetry-top">
                    <h1>Users</h1>
                    <div className="telemetry-top-right">
                        <AddProducts/>
                        <Profile image={profileImage}/>
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1}/>
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-user-details-tab">
                        <div className="telemetry-setting-tab-content">
                            <div className="telemetry-setting-box">
                                <h2>Profile Information</h2>
                                <div className="telemetry-box-ov">
                                    <Avatar/>
                                    <div className="telemetry-setting-block-2">
                                        <TelemetryFormBlock
                                            label="First"
                                            inputType="readonly"
                                            name="first"
                                            id="first"
                                            value={userDetails?.first_name}
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="Last"
                                            inputType="text"
                                            name="last"
                                            id="last"
                                            value={userDetails?.last_name}
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="Email"
                                            inputType="text"
                                            name="website"
                                            id="website"
                                            value={userDetails?.email}
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="ID"
                                            inputType="text"
                                            name="id"
                                            id="id"
                                            value={userDetails?.id}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <TelemetryAccordion title={"Sites"}>
                        <SitesTable paginationPerPage={10} userId={userId}/>
                    </TelemetryAccordion>

                    <TelemetryAccordion title={"Events & Webhooks"}>
                        <ProductEventsLogTable paginationPerPage={10} type={'all'} userId={userId}/>
                    </TelemetryAccordion>
                </section>
            </main>
        </div>
    );
}
  
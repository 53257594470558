import React, {useState} from 'react';
import profileImage from '../../img/customer-icon.svg';

const Avatar = () => {
    const [preview, setPreview] = useState(profileImage);
    return (
        <div className="telemetry-box-profile-up">
            <div className='profile-thumbnail'>
                <img src={preview} alt="Customizer" className='profile-image'/>
            </div>
        </div>
    );
};

export default Avatar;

import React, {createContext, useState, useContext, useEffect} from 'react';
import {getUserStoresWithProducts} from "../services/apiService";
import {AuthContext} from "./AuthContext";


// Create a Context for the product
const TelemetryContext = createContext();

export const useTelemetryContext = () => {
    return useContext(TelemetryContext);
};

export const TelemetryProvider = ({children}) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [stores, setStores] = useState([]);
    const {token} = useContext(AuthContext);

    useEffect(() => {
        // Fetch data only if user is authenticated
        if (token && !selectedProduct && stores.length < 1) {
            getUserStoresWithProducts()
                .then((data) => {
                    setStores(data);
                    if (data.length > 0) {
                        // Check for the first store
                        const firstStore = data[0];

                        if (firstStore && firstStore.products.length > 0) {
                            // If there are products, set the selected product to the first product of the first store
                            setSelectedProduct(firstStore.products[0]);
                            setSelectedStore(firstStore);
                        } else {
                            // No products found in any store, redirect to the "Create Product" page
                            window.location.href = '/add-product'; // Replace with the actual route to create a product
                        }
                    } else {
                        // No stores found, redirect to the "Create Store" page
                        window.location.href = '/add-store'; // Replace with the actual route to create a store
                    }

                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                });

        }
    }, [token]);

    return (
        <TelemetryContext.Provider
            value={{selectedProduct, setSelectedProduct, stores, setStores, selectedStore, setSelectedStore}}>
            {children}
        </TelemetryContext.Provider>
    );
};

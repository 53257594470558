import React, { useRef } from 'react';
import { Tooltip } from 'react-tooltip';

const TelemetryFormBlock = ({ 
    label, 
    inputType, 
    value, 
    name, 
    id, 
    placeholder, 
    infoTitle, 
    width, 
    regenerate, 
    copy, 
    options, 
    onChange,
    disabled
}) => {
    const inputRef = useRef(null);

    const handleCopy = () => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
            // Optionally, you can add a visual feedback to the user
            alert('Text copied to clipboard!');
        }
    };

    return (
        <div className={`telemetry-form-block ${width ? width : ''}`}>
            <label htmlFor={id}>{label}</label>
            <div className="telemetry-input-block">
                <div className="telemetry-input-box">
                    {inputType === 'select' ? (
                        <select 
                            name={name} 
                            id={id} 
                            value={value}
                            onChange={onChange}
                            disabled={disabled} 
                        >
                            {options && options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <input 
                            type={inputType} 
                            value={value} 
                            name={name} 
                            id={id} 
                            placeholder={placeholder} 
                            onChange={onChange}
                            ref={inputRef}
                            disabled={disabled}
                        />
                    )}
                    <Tooltip 
                        style={{ backgroundColor: "#ffffff" }} 
                        id={`tooltip-${name ? name : ''}`} 
                    />
                    {infoTitle && 
                        <span 
                            className="info-icon" 
                            data-tooltip-variant="light" 
                            data-tooltip-id={`tooltip-${name ? name : ''}`} 
                            data-tooltip-place="top-end" 
                            data-tooltip-content={infoTitle}
                        />
                    }
                    {copy && 
                        <span 
                            className="copy-icon" 
                            onClick={handleCopy} 
                            data-tooltip-variant="light" 
                            data-tooltip-id={`tooltip-${name ? name : ''}`} 
                            data-tooltip-place="top-end" 
                            data-tooltip-content="Click to copy"
                        />
                    }
                </div>
                {regenerate && <button className="regenerate">Re-Generate</button>}
            </div>
        </div>
    );
};

export default TelemetryFormBlock;

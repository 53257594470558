import React, {useEffect, useState} from 'react';
import {
    ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine
} from 'recharts';
import './graph.css';
import {fetchDashboardChartData} from "../../../services/apiService";
import {processEffectiveGrowthResults} from "../../../utils/dataProcessing";
import {colors, effectiveGrowthChartColors} from '../../../utils/constants';

const EffectiveGrowthTrendLineChart = ({
                                           product_id,
                                           title,
                                           meta_key,
                                           group_by_period,
                                           start_date,
                                           end_date,
                                           compare_start_date,
                                           compare_end_date,
                                           showGrowth
                                       }) => {
    const [data, setData] = useState([]);
    const [hoveredPeriod, setHoveredPeriod] = useState(null);
    const [overallTotals, setOverallTotals] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const responseData = await fetchDashboardChartData(
                    product_id,
                    meta_key,
                    group_by_period,
                    start_date,
                    end_date,
                    compare_start_date,
                    compare_end_date,
                    showGrowth
                );

                const currentData = processEffectiveGrowthResults(responseData.current);
                const previousData = showGrowth
                    ? processEffectiveGrowthResults(responseData.previous)
                    : [];

                const processedData = currentData.map((item, index) => ({
                    ...item,
                    previous_growth:
                        showGrowth && previousData[index]
                            ? previousData[index].growth
                            : null,
                    previous_total_activations:
                        showGrowth && previousData[index]
                            ? previousData[index].sum_of_total_activations
                            : null,
                    previous_total_deactivations:
                        showGrowth && previousData[index]
                            ? previousData[index].sum_of_deactivations
                            : null,
                }));

                setData(processedData);

                // Calculate overall totals with null checks
                const totals = {
                    total_activations: responseData.current.sum_of_total_activations,
                    total_deactivations: responseData.current.sum_of_deactivations,
                    growth: responseData.current.calculated_growth,
                    previous_growth:
                        showGrowth && responseData.previous
                            ? responseData.previous.calculated_growth
                            : null,
                    previous_total_activations:
                        showGrowth && responseData.previous
                            ? responseData.previous.sum_of_total_activations
                            : null,
                    previous_total_deactivations:
                        showGrowth && responseData.previous
                            ? responseData.previous.sum_of_deactivations
                            : null,
                };
                setOverallTotals(totals);

                setHoveredPeriod(null);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchChartData();
    }, [
        product_id,
        meta_key,
        group_by_period,
        start_date,
        end_date,
        compare_start_date,
        compare_end_date,
        showGrowth,
    ]);
    const handleMouseMove = (event) => {
        if (event && event.activeLabel) {
            setHoveredPeriod(event.activeLabel);
        } else {
            setHoveredPeriod(null);
        }
    };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Determine which data to display: hovered period or overall totals
    const filteredData = hoveredPeriod
        ? data.find((item) => item.period === hoveredPeriod)
        : overallTotals;

    const period = hoveredPeriod ? hoveredPeriod : "Overall";

    const growth =
        filteredData.growth !== null ? `${filteredData.growth}` : "N/A";
    const previousGrowth =
        showGrowth && filteredData.previous_growth !== null
            ? `${filteredData.previous_growth}`
            : "N/A";
    const previousTotalActivations =
        showGrowth && filteredData.previous_total_activations !== null
            ? filteredData.previous_total_activations
            : "N/A";
    const previousTotalDeactivations =
        showGrowth && filteredData.previous_total_deactivations !== null
            ? filteredData.previous_total_deactivations
            : "N/A";

    return (
        <div className="telemetry-block-container">
            <div className="telemetry-block-column">
                <div className="telemetry-block-header green-badge">
                    <h3>{title}</h3>
                    <span className="badge"></span>
                </div>
                <div className="chart-container">
                    <ResponsiveContainer width="100%" height={550}>
                        <ComposedChart
                            data={data}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={() => setHoveredPeriod(null)}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="period"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <ReferenceLine x={0} stroke="#000"/>
                            <Bar
                                dataKey="total_activations"
                                fill={effectiveGrowthChartColors.activations}
                            />
                            <Bar
                                dataKey="total_deactivations"
                                fill={effectiveGrowthChartColors.deactivations}
                            />
                            <Line
                                type="monotone"
                                dataKey="growth"
                                stroke={effectiveGrowthChartColors.growth}
                            />
                            {showGrowth && (
                                <Line
                                    type="monotone"
                                    dataKey="previous_growth"
                                    stroke={effectiveGrowthChartColors.previousGrowth}
                                    strokeDasharray="3 4 5 2"
                                />
                            )}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="telemetry-block-column">
                <div className="table-container">
                    <table>
                        <thead>
                        <tr>
                            <th>Metric</th>
                            <th>{period}</th>
                            {showGrowth && <th>Previous Period</th>}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>   <span className="badge"
                                         style={{backgroundColor: effectiveGrowthChartColors.activations}}>
                                    </span> Total Activations
                            </td>
                            <td>{filteredData.total_activations}</td>
                            {showGrowth && <td>{previousTotalActivations}</td>}
                        </tr>
                        <tr>
                            <td><span className="badge"
                                      style={{backgroundColor: effectiveGrowthChartColors.deactivations}}>
                                    </span> Total Deactivations
                            </td>
                            <td>{filteredData.total_deactivations}</td>
                            {showGrowth && <td>{previousTotalDeactivations}</td>}
                        </tr>
                        <tr>
                            <td><span className="badge" style={{backgroundColor: effectiveGrowthChartColors.growth}}></span> Growth</td>
                            <td className={growth >= 0 ? 'positive-growth' : 'negative-growth'}>
                                                <span className='badge'>
                                                    {growth >= 1000
                                                        ? `${(growth / 1000).toFixed(1)}K%`
                                                        : growth < 0
                                                            ? `${growth}%`
                                                            : `+${growth}%`}
                                                </span>
                            </td>
                            {showGrowth && <td className={previousGrowth >= 0 ? 'positive-growth' : 'negative-growth'}>
                                                <span className='badge'>
                                                    {previousGrowth >= 1000
                                                        ? `${(previousGrowth / 1000).toFixed(1)}K%`
                                                        : previousGrowth < 0
                                                            ? `${previousGrowth}%`
                                                            : `+${previousGrowth}%`}
                                                </span>
                            </td>
                            }
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
        ;
};
export default EffectiveGrowthTrendLineChart;

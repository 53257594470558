import React from 'react';
import profileicon  from '../../img/customer-icon.svg'; // Update the path as necessary

export default function AddNewProductsForm() {
  return (
    <div>
      <div className="telemetry-form-inline">
        <h4>Integration purpose:</h4>
        <div className="telemetry-form-box">
          <label>
            <input type="checkbox" /> Sell through Telemetry
          </label>
        </div>
      </div>
      
      <div className="telemetry-form-inline">
        <h4>Store:</h4>
        <div className="telemetry-form-box">
          <select>
            <option value="">Select Store</option>
            <option value="">Store 1</option>
            <option value="">Store 2</option>
            <option value="">Store 3</option>
          </select>
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Widget Title:</h4>
        <div className="telemetry-form-box">
          <input type="text" placeholder="Enter the title" />
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Downloadable File Name:</h4>
        <div className="telemetry-form-box">
          <input type="text" placeholder="Enter the Slug" />
        </div>
      </div>
      <div className="telemetry-form-inline">
        <h4>Widget Icon:</h4>
        <div className="telemetry-form-box">
          <div className="telemetry-upload-logo">
            <div className="update-icon">
              <img src={profileicon} alt="Profileicon" />
            </div>
            <div className="upload-action">
              <label>
                Upload <input type="file" />
              </label>
              <p>
                200px X 200px
                <br />
                Up to 200KB
              </p>
            </div>
          </div>
        </div>
      </div>
      <button type="submit">Get Started 🚀</button>
    </div>
  );
}

import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../img/logo.svg';
import { AuthContext } from '../contexts/AuthContext';
import { useTelemetryContext } from "../contexts/TelemetryContext";

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const { selectedProduct } = useTelemetryContext();
    const [loginSuccessful, setLoginSuccessful] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'application/json'
                },
                body: new URLSearchParams({ email, password })
            });

            if (!response.ok) {
                setError('Invalid email or password');
            } else {
                const data = await response.json();
                login(data.access_token); // Use the login function from the context
                setLoginSuccessful(true); // Indicate login was successful
            }
        } catch (error) {
            setError(error.message);
        }
    };

    // Use useEffect to navigate after login and once selectedProduct is set
    useEffect(() => {
        if (loginSuccessful && selectedProduct) {
            navigate(`/stores/${selectedProduct.store_id}/plugins/${selectedProduct.id}/dashboard`);
        }
    }, [loginSuccessful, selectedProduct, navigate]);

    return (
        <div className="wpb-telemetry-loginpage">
            <header>
                <img src={logo} alt="Telemetry"/>
            </header>
            <div className="wpb-telemetry-form-wrapper">
                <form onSubmit={handleSubmit} className="telemetry-loginform" id="telemetry-loginform"
                      name="telemetry_loginform">
                    <h1>Sign in</h1>
                    <div className="wpb-telemetry-form">
                        <div className="wpb-telemetry-field wpb-name-field">
                            <input
                                id="email"
                                name="telemetry_name"
                                type="text"
                                placeholder="Email address *"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <div className="validation-msg" id="email-error">This field is required!</div>
                            <div className="validation-msg" id="email-format-error">Invalid email format!</div>
                        </div>
                        <div className="wpb-telemetry-field wpb-password-field">
                            <input
                                id="password"
                                name="telemetry_password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <div className="validation-msg" id="password-error">This field is required!</div>
                        </div>
                        {error && <div className="error" style={{float: 'right'}}>{error}</div>}
                        <a href="#" className="forget-pass">Forgot your password?</a>
                        <button type="submit">Sign in to your dashboard</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

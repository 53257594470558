import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchStoreSites } from "../../services/apiService";
import { useTelemetryContext } from "../../contexts/TelemetryContext";
import {useNavigate, useParams} from 'react-router-dom';

function StoreSites({ paginationPerPage, searchTerm, userId = null, storeId }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage);
    // const { selectedProduct } = useTelemetryContext();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await fetchStoreSites(storeId, currentPage, paginationPerPage, userId, searchTerm);
                setData(responseData.data || []);
                setCurrentPage(responseData.current_page);
                setTotalRows(responseData.total);
                setPerPage(responseData.per_page);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [storeId, currentPage, paginationPerPage, searchTerm]);

    const handleSiteDetailClick = (siteId) => {
        // Todo
        // Redirect to Site detail page only without product
    };

    const handleUserDetailClick = (userId) => {
        // Todo
        // Redirect to User detail page only without product
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            cell: row => <span className="id-span" onClick={() => handleSiteDetailClick(row.id)}>{row.id}</span>,
        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,
            cell: row => <span onClick={() => handleSiteDetailClick(row.id)} className="link-icon">{row.address}</span>,
        },
        {
            name: 'Installed at',
            selector: row => row.installed_at,
            sortable: true,
            cell: row => new Date(row.installed_at).toLocaleDateString(),
        },
        {
            name: 'User ID',
            selector: row => row.user_id,
            sortable: true,
            cell: row => <span className="link-icon" onClick={() => handleUserDetailClick(row.user_id)}>{row.user_id}</span>,
        },
        {
            name: 'SDK Version',
            selector: row => row.sdk_version,
            sortable: true,
        },
        {
            name: 'Version',
            selector: row => row.version,
            sortable: true,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            responsive
            className="custom-data-table"
        />
    );
}

export default StoreSites;

import { Outlet, Link } from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import customizerLogo from '../../img/customizer-logo.svg';
import profileicon from '../../img/customer-icon.svg';
import AddProductsForm from '../reused-components/AddProductsForms';
export default function AddNewProduct() {
	document.title = "Add New Products | Telemetry";
	return (
		<div className="telemetry-page-wrapper">
		<header className="telemetry-header">
			<div className="telemetry-logo-section">
				<a href="#"><img src={smlogo} alt="Telemetry" /></a>
			</div>
			<div className="telemetry-top">
				<h1>New Product</h1>
				<div className="telemetry-top-right">
					<AddProducts/>
					<Profile image={profileImage} />
				</div>
			</div>
		</header>
		<main className="telemetry-main">
			<aside className="telemetry-sidebar">
			<ToggleDropdown tabactive={1} tabactive2={1} />
			</aside>
			<section className="telemetry-content">
				<div className="telemetry-setting-tab-content">
					<div className="telemetry-product">
						<h2>Add New product with Telemetery</h2>
						<div className="telemetry-add-products">
							<AddProductsForm />
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
	);
}
  